.footer {
    background-color: var(--indigo-800);
    display: flex;
    justify-content: center;
    padding: 1.25rem 0;
}

.footer a {
    color: var(--gray-300);
    font-size: 1rem;
}