.wrapper {
  /* max-width: 70rem; */
  margin: 2rem auto;
  padding: 0 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* grid-template-columns: 1fr; */
  gap: 2rem;
  /* align-items: flex-start; */
}

.mainHeader {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.infoMap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}

.inputMap {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-bottom: 1.5rem;
  gap: 0.5rem;
}

.inputMap a {
  color: var(--green-500);
}