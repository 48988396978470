.header {
    background-color: var(--gray-800);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem 0;
}

.header img {
    height: 2.0rem;
    margin-right: 1rem;
}